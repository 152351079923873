import "@babel/polyfill";
import { Card, CardProps } from '@mui/material';
import 'core-js/es/symbol';
import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import "regenerator-runtime/runtime";
import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundaryComponent/ErrorBoundaryComponent";
import reportWebVitals from './reportWebVitals';



ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
