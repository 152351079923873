import IndexRouter from "./routes"
import './App.css';
import './index.css';
import { Card, CardProps } from "@mui/material";


const App = () => {

    const cardProps: CardProps = {
        sx: {
            width: {
                sm: 700,
                md: 700,
            },
        }
    }

    return (
        <div className="App">
            <div className="App-header">
                <Card {...cardProps}>
                    <IndexRouter />
                </Card>
            </div>
        </div>
    )
}

export default App;