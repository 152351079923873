import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RoutingComponent from "../components/RoutingComponent/RoutingComponent";


const ValidateOrderView = lazy(() => import("../views/ValidateOrderView/ValidateOrderView"));
const FileUploadView = lazy(() => import("../views/FileUploadView/FileUploadView"));
const ConfirmationView = lazy(() => import("../views/ConfirmationView/ConfirmationView"));
const ErrorView = lazy(() => import("../views/ErrorView/ErrorView"));
const NotFoundView = lazy(() => import("../views/NotFoundView/NotFoundView"));


const IndexRouter = () => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const setLoader = (isloading: boolean) => {
        setIsLoading(isloading);
    }

    return (
        <>
            <Router>
                <Suspense fallback={
                    <>
                        <Box p={30} >
                            <CircularProgress />
                        </Box>
                    </>
                }>
                    <Routes>

                        <Route
                            path='/'
                            element={
                                <RoutingComponent>
                                    <ValidateOrderView setLoader={setLoader} />
                                </RoutingComponent>
                            }
                        />

                        <Route path='/upload' element={
                            <RoutingComponent>
                                <FileUploadView />
                            </RoutingComponent>

                        } />

                        <Route path='/confirmation' element={
                            <RoutingComponent>
                                <ConfirmationView  setLoader={setLoader}/>
                            </RoutingComponent>
                        } />

                        <Route path='/error' element={
                            <RoutingComponent>
                                <ErrorView />
                            </RoutingComponent>
                        } />


                        <Route path='*' element={
                            <RoutingComponent>
                                <NotFoundView />
                            </RoutingComponent>
                        } />
                    </Routes>
                </Suspense>
            </Router>
            <Backdrop open={isLoading}>
                <CircularProgress />
            </Backdrop>
        </>
    )

};

export default IndexRouter;