import React from "react";
import { NavigateFunction } from "react-router-dom";



export class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }



    componentDidCatch(error: any, errorInfo: any) {
        this.props.navigate('/error', { replace: true });

    }

    render() {
        return this.props.children;
    }
}