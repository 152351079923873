import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "../ErrorBoundaryComponent/ErrorBoundaryComponent";


const RoutingComponent = (props: any) => {
    const navigate = useNavigate();


    return (
        <ErrorBoundary navigate={navigate}>
            {props.children}
        </ErrorBoundary>
    );
}

export default RoutingComponent;